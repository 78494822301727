import api from '@/services/api';

const url = 'users';

const getUserEmailByCpf = (cpf) => api.get(url.concat(`/email/${cpf}`));

const sendEmailWithCode = (user) => api.post(url.concat('/send-password-code'), user);

const resetUserPassword = (changedPassword) => api.post(url.concat('/reset-password'), changedPassword);

const resetPasswordOldUser = (changedPassword) => api.post(url.concat('/reset-password-old-user'), changedPassword);

export default {
  getUserEmailByCpf, sendEmailWithCode, resetUserPassword, resetPasswordOldUser,
};
