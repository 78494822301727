<template>
  <v-card>
    <v-container fill-height>
      <v-form
        ref="formAuthUser"
        @submit.prevent="submit"
      >
        <v-row>
          <v-col cols="12">
            <v-alert type="info">
              Este usuário já está cadastrado em nosso sistema autorizador. Para efetuar a troca de senha insira o
              código enviado ao e-mail fornecido na etapa anterior.
            </v-alert>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="secretCode"
              outlined
              maxlength="6"
              counter
              dense
              label="Código Secreto"
              :rules="$rules.required"
              required
            />
          </v-col>

          <v-col cols="6">
            <v-text-field
              v-model="password"
              outlined
              dense
              label="Senha"
              type="password"
              :rules="$rules.required"
              required
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="passwordConfirmation"
              outlined
              dense
              label="Confirmar Senha"
              type="password"
              :rules="$rules.confirmPassword(password, passwordConfirmation)"
              required
            />
          </v-col>

          <v-col class="d-flex justify-space-between">
            <v-btn
              color="primary"
              type="submit"
            >
              Alterar Senha
            </v-btn>

            <v-btn
              color="secondary"
              @click="goBack(2)"
            >
              Voltar
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import ChangePasswordService from '../../../services/change-password.service';

export default {
  data() {
    return {
      secretCode: '',
      password: '',
      passwordConfirmation: '',
    };
  },
  methods: {
    goBack(step) {
      this.$emit('go-back', step);
    },

    resetForm() {
      this.$refs.formAuthUser.reset();
      this.$refs.formAuthUser.resetValidation();
    },

    async submit() {
      if (!this.$refs.formAuthUser.validate()) return;
      if (this.password && this.passwordConfirmation === '') {
        this.$toast.error('Preencha os campos, por favor');
        return;
      }

      try {
        await ChangePasswordService.resetUserPassword({
          secretCode: this.secretCode,
          password: this.password,
          passwordConfirmation: this.passwordConfirmation,
        });
        this.$toast.success('Senha trocada com sucesso!');
        this.goBack(1);
      } catch (e) {
        this.$handleHttpError(e);
      } finally {
        this.resetForm();
      }
    },
  },
};
</script>

<style>

</style>
